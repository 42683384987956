<template>
  <div class="py-md-5">
    <b-container fluid class="my-bw-4 section-header">
      <b-container>
        <b-row class="mb-4">
          <b-col cols="12">
            <p class="p-bw-bold highlighter lightblue mx-auto">
              {{ $t("common.personal") }}
            </p>
            <h1 class="display-bw-3">
              {{ $t("personal.header.title") }}
            </h1>
            <p class="p-bw mb-md-5 px-5">
              {{ $t("personal.header.content") }}
            </p>
          </b-col>
        </b-row>
      </b-container>
    </b-container>

    <b-container class="my-bw-4 text-md-left section--barliner-method">
      <div class="section-barliner-method">
        <div class="img-personal-group-a" alt="Barliner Workout"></div>
        <div class="barliner-method-text">
          <p class="p-bw-bold mb-2">
            {{ $t('personal.section1.subtitle') }}
          </p>
          <h2 class="h2-bw mb-4">
            {{ $t('personal.section1.title') }}
          </h2>
          <p class="p-bw"  v-html="$t('personal.section1.content')"></p>
            <b-button
              variant="dark"
              class="mt-4 w-75"
              :to="{ name: 'Personal', hash: '#contactBW'}"
            >
              {{ $t('personal.section1.buttonLabel') }}
            </b-button>
        </div>
      </div>
    </b-container>

    <b-container fluid class="my-bw-4 section-header">
      <b-container>
        <b-row class="mb-4">
          <b-col cols="12">
            <p class="p-bw mx-auto">
              {{ $t("personal.section2.subtitle") }}
            </p>
            <h1 class="display-bw-3">
              {{ $t("personal.section2.title") }}
            </h1>
            <p class="p-bw mb-md-5 px-5">
              {{ $t("personal.section2.content") }}
            </p>
          </b-col>
        </b-row>
      </b-container>
    </b-container>

    <b-container class="my-bw-4 text-md-left section--trainer-denis">
      <div class="section-trainer-odd">
        <div class="img-trainer-denis" alt="Barliner Workout"></div>
        <div class="trainer-text">
          <p class="p-bw-bold mb-2">
            {{ $t('personal.section3.subtitle') }}
          </p>
          <h2 class="h2-bw mb-4">
            {{ $t('personal.section3.title') }}
          </h2>
          <p class="p-bw"  v-html="$t('personal.section3.content')"></p>
          <p class="p-bw">{{ $t('common.trainWithMeFor') }}</p>
          <ul>
            <li>{{ $t('common.strength') }}</li>
            <li>{{ $t('common.endurance') }}</li>
            <li>{{ $t('common.skills') }}</li>
            <li>{{ $t('common.handstand') }}</li>
            <li>{{ $t('common.muscleUp') }}</li>
            <li>{{ $t('common.businessTraining') }}</li>
          </ul>
        </div>
      </div>
    </b-container>

    <b-container style="display: none;" class="my-bw-4 text-md-left section--trainer-generich">
      <div class="section-trainer-even">
        <div class="trainer-text">
          <p class="p-bw-bold mb-2">
            {{ $t('personal.section4.subtitle') }}
          </p>
          <h2 class="h2-bw mb-4">
            {{ $t('personal.section4.title') }}
          </h2>
          <p class="p-bw"  v-html="$t('personal.section4.content')"></p>
          <p class="p-bw">{{ $t('common.trainWithMeFor') }}</p>
          <ul>
            <li>{{ $t('common.strength') }}</li>
            <li>{{ $t('common.endurance') }}</li>
            <li>{{ $t('common.skills') }}</li>
            <li>{{ $t('common.handstand') }}</li>
            <li>{{ $t('common.muscleUp') }}</li>
            <li>{{ $t('common.businessTraining') }}</li>
          </ul>
        </div>
        <div class="img-trainer-generich" alt="Barliner Workout"></div>
      </div>
    </b-container>

    <contact></contact>
  </div>
</template>

<script>
export default {
  name: 'Inclusive',
  components: {
    Contact: () => import('@/components/Contact')
  },
  data () {
    return {
      quotes: [
        {
          id: 1,
          name: 'Oksana',
          message: 'Joined the comunity 5 years ago. We went through many things. I like to see the progress of the community. Thanks to Barliner I got motivated to do a Trainer licence and it brought me to my jobs as a trainer today. Specially, I like this feeling in the training with Barliner. People are very cool, heartwarming atmosphere, a lot of nonstandard exercises!',
          joiningYear: '2016'
        },
        {
          id: 2,
          name: 'Mara',
          message: 'Right from the beginning of the first training I felt so welcome and like I was part of the group. It’s great how a group of so many different people can be such a strong team and still be so open to accept new people right away. I absolutely love the spirit of our crew. Everybody looks out for each other, supports their teammates and motivates and corrects them. Which is why the atmosphere at every training is very good and also intense. That is one of the reasons why I can push myself harder than I could before. \n\nAnother awesome thing is that the training is different every time and Denis always looks for specific exercises that are the right ones for you. I just started but I can already feel improvements (not only physical but also in my mindset) and have to say, that this is an important part of my time here in Berlin. Cheers!',
          joiningYear: '2017'
        },
        {
          id: 3,
          name: 'Simon',
          message: 'If you want to: be trained by one of the nicest guys you will ever meet; train alongside a community of friendly, focused and hard-working individuals from around the world; improve your strength, flexibility and muscular definition in a more efficient way than you will ever do in a gym; and have a s**t-tonne of fun and laughs while doing it, then training with Denis at Barliner is the place for you. Well, at least, this has been my experience so far!\n\n I am so so happy I joined the Barliner clan and discovered calisthenics. Every week I am making progress and every week I am buzzing to train. Denis always keeps the sessions fresh and exciting, pushes you to your absolutel limit and manages to do so while making both you and he smile.\n\n Thanks to Denis and everyone at Barliner for the love and support!',
          joiningYear: '2016'
        },
        {
          id: 4,
          name: 'Robyn',
          message: 'Gymnastics for adults!\n\n After 11 months of looking for a sport that pushes my mind and body I finally went to one of the Barliner Calisthenics classes and fell in love! I had done crossfit for a few years but it really lacked enough focus in the gymnastic element of the training.\n\n I love this sport, both as a mental challenge and as exercise to tone my body and push me further every time. Additionally, it is great finding a sport with such a friendly group of people like the Barliner Calisthenics community.\n\n And the best thing...Den is an amazing instructor. He pushes you but is aware of injuries. I have been to several calisthenics classes in Berlin but now I only go to his classes as he is THE BEST!!!',
          joiningYear: '2019'
        },
        {
          id: 11,
          name: 'Mon',
          message: 'I was immediately struck by how lovely and supportive everyone in this community is. When I came I couldn’t even do a single pull up, but with the help and experience of Denis I quickly overcame a lot of my difficulties and currently I am achieving movements that months ago sounded impossible.\n\n The care and knowledge that Denis displays in every single training session is unparalleled and I feel really grateful for being part of this family.',
          joiningYear: '2021'
        },
        {
          id: 5,
          name: 'Alex',
          message: 'Finding Barliner has been a massive part of my Berlin experience. Denis brings a knowledgeable, thoughtful, and non-judgemental approach to his training. The classes are great mix of basics and skills training, focusing on long-term development, rather than fast results. In addition to being an awesome trainer, Denis has also created a fun and supportive community for calisthenics enthusiasts of all levels.\n\n If you want to get fit, learn skills (the right way), and meet an awesome bunch of people, then this is place for you.',
          joiningYear: '2015'
        },
        {
          id: 6,
          name: 'Carsten',
          message: 'Denis never gets tired of showing you, how to do the movements really correctly, down to the smallest muscles in your body. He always brings new exercises to his classes, and scaling options for every level of training. He’s a great support, and I got so much better over time. It makes so much fun to train with him. Thumbs Up for the best coach I can imagine!',
          joiningYear: '2019'
        },
        {
          id: 7,
          name: 'Liviu',
          message: 'Keep up the sword of good cheer, and thank you again for your efforts and enthusiasm!',
          joiningYear: '2020'
        },
        {
          id: 8,
          name: 'Engin',
          message: 'Ich genieße jedes Training und ganz gleich wie gut oder schlecht ich mich fühle vor dem Training, danach geht es mir immer besser. Es tut mir offensichtlich körperlich gut aber besonders mental ist es eine super Therapie.',
          joiningYear: '2020'
        },
        {
          id: 9,
          name: 'Nick',
          message: 'Denis ist seit über einem Jahr mein Trainer. Meistens trainiere ich viermal pro Woche. Er bietet ein abwechslungsreiches und strukturiertes Training an. Es ist genau auf mein Level ausgerichtet und wird stetig an meine Fortschritte angepasst. Denis legt viel Wert auf das richtige Warm Up und die korrekte Ausführung von Bewegungen. Wir trainieren zuerst auf Bewegungen hin und im zweiten Schritt auf Wiederholungen und Gewichte. Das Besondere an Denis ist, ist dass er alle Übungen selbst perfekt beherrscht und immer motiviert ist. Das Training macht sehr viel Spaß!',
          joiningYear: '2019'
        },
        {
          id: 10,
          name: 'Kirill',
          message: 'Train till you faint. Nothing better than Barliner calithenics to 🔥 off that Corona fat and impress the Ex who dumped you during the lockdown.',
          joiningYear: '2020'
        }
      ],
      swiperOptionQuotes: {
        loop: true,
        grabCursor: true,
        initialSlide: 0,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  computed: {
    swiperQuotes () {
      return this.$refs.quotesSwiper.$swiper
    }
  },
  methods: {
    prevQuote () {
      this.swiperQuotes.slidePrev()
    },
    nextQuote () {
      this.swiperQuotes.slideNext()
    }
  }
}
</script>

<style lang="scss" scoped>
  .section-header {
    .card-trainer {
      height: 480px;
      margin-right: .75rem;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  .section-barliner-method {
    display: grid;

    @include breakpoint(lg) {
      column-gap: 6rem;
      grid-template-columns: 1fr 1fr;
    }

    .img-personal-group-a {
      @extend .img-background;
      height: 100%;
      min-height: 288px;
      background-image: url('../assets/imgs/landing_commitment_2x.png');
      background-size: cover;
      background-position: center;

      @include breakpoint(lg) {
        min-height: 492px;
        background-image: url('../assets/imgs/landing_commitment_3x.png');
      }
    }

    .barliner-method-text {
      grid-row: 1;
      border-top: solid 1px #eff0f8;
      padding-top: 2.5rem;
      padding-bottom: 1.5rem;

      h2 {
        margin-left: 2rem;
        margin-right: 2rem;
      }

      @include breakpoint(lg) {
        grid-row: auto;
        border-top: 0;
        align-self: center;
        padding-right: 6rem;
        padding-bottom: 0;

        h2 {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }

  .section-trainer-odd {
    display: grid;

    @include breakpoint(lg) {
      column-gap: 6rem;
      grid-template-columns: 3fr 2fr;
      padding-left: 2rem;
    }

    .img-trainer-denis {
      @extend .img-background;
      height: 100%;
      min-height: 288px;
      background-image: url('../assets/imgs/trainer_denis_2x.jpg');
      background-size: cover;
      background-position: center;

      @include breakpoint(lg) {
        min-height: 492px;
        background-image: url('../assets/imgs/trainer_denis_3x.jpg');
      }
    }

    .trainer-text {
      grid-row: 1;
      border-top: solid 1px #eff0f8;
      padding-top: 2.5rem;
      padding-bottom: 1.5rem;

      h2 {
        margin-left: 2rem;
        margin-right: 2rem;
      }

      @include breakpoint(lg) {
        grid-row: auto;
        border-top: 0;
        align-self: center;
        padding-right: 6rem;
        padding-bottom: 0;

        h2 {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }

  .section-trainer-even {
    display: grid;

    @include breakpoint(lg) {
      column-gap: 6rem;
      grid-template-columns: 2fr 3fr;
      padding-left: 2rem;
    }

    .img-trainer-generich {
      @extend .img-background;
      height: 100%;
      min-height: 288px;
      background-image: url('../assets/imgs/landing_group_b_2x.jpg');
      background-size: cover;
      background-position: center;

      @include breakpoint(lg) {
        min-height: 492px;
        background-image: url('../assets/imgs/landing_group_b_2x.jpg');
      }
    }

    .trainer-text {
      grid-row: 1;
      border-top: solid 1px #eff0f8;
      padding-top: 2.5rem;
      padding-bottom: 1.5rem;

      h2 {
        margin-left: 2rem;
        margin-right: 2rem;
      }

      @include breakpoint(lg) {
        grid-row: auto;
        border-top: 0;
        align-self: center;
        padding-right: 6rem;
        padding-bottom: 0;

        h2 {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
</style>
